/**
 * @file    This module will be used to add svg spans for components across the site
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    May 2018
 */

$.Sq = $.Sq || {};

$.Sq.JsAddSvgs = (function() {
    const win = typeof window === 'undefined' ? {} : window;

    if (!win.document) {
        return;
    }

    const warn = console.warn.bind(console);
    const MSG_NO_NAME = 'No svg name found in the called init function';

    const CONFIG = {
        selector: 'js-svg',
        spanClass: 'c-svg',
        svgName: '',
        svgUrl: '/__data/assets/git_bridge/0003/334830/matrix-files/styles/mysource_files/sprite.svg',

        // automatically search for the class name which contains the svg name by prefix
        searchClass: false,
        classPrefix: '',
    };

    /**
     * Create svg node
     *
     * @param config
     * @param config.svgUrl     {string}
     * @param config.svgName    {string}
     * @param config.spanClass  {string}
     *
     * @return {HTMLSpanElement}
     */
    const _createSvg = ({svgUrl, svgName, spanClass}) => {
        const span = win.document.createElement('span');

        span.className = spanClass;
        span.innerHTML = `<svg class="c-icon">
            <use xlink:href="${svgUrl}#${svgName}" />
        </svg>`;

        return span;
    };

    /**
     * Get svg name from classNames of the given element
     * @param el
     * @param config
     * @param config.classPrefix {string}
     *
     * @return {string}
     */
    const _getSvgName = (el, {classPrefix}) => {
        const reg = new RegExp(`(?:${classPrefix})(\\w+)`, 'i');
        const matches = (el.className || '').match(reg);

        return matches ? matches[1] : '';
    };

    const init = (customConfig) => {
        const config = Object.assign( {}, CONFIG, customConfig );

        if (!config.searchClass && !config.svgName) {
            warn(MSG_NO_NAME);
            return;
        }

        Array.prototype.slice.call(win.document.querySelectorAll(config.selector)).forEach((el) => {
            if (config.searchClass) {
                config.svgName = _getSvgName(el, config);
            }

            el.appendChild( _createSvg(config) );
        });
    };

    return {
        init,
    };
})();
