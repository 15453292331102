$.Sq = $.Sq || {};

$.Sq.Table = (function( module ) {

  // debounce function
  // https://davidwalsh.name/javascript-debounce-function
  module.debounce = (func, wait, immediate) => {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  module.addClass = (element, className) => {
    if (element.classList) {
      element.classList.add(className);
    }
    else {
      element.className += '' + className;
    }
  };

  module.removeClass = (element, className) => {
    let regex = new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi');
    if (element.classList) {
      element.classList.remove(className);
    }
    else {
      element.className = element.className.replace(regex, ' ');
    }
  };

  module.cloneClassList = (srcEle, destEle) => {
    for(let i = 0; i < srcEle.classList.length; i += 1) {
      destEle.classList.add(srcEle.classList[i]);
    }
  };

  module.isTblScrolledToRight = () => {
    const containers = document.querySelectorAll('.e-tbl__inner');

    for (let i = 0; i < containers.length; i += 1) {
      let container = containers[i],
        containerParent = container.parentElement,
        tbl = container.getElementsByTagName('table')[0];

      container.onscroll = () => {
        let tblRect = tbl.getBoundingClientRect(),
        containerRect = container.getBoundingClientRect();

        if (tblRect.right <= containerRect.right + 1) {
          module.addClass(containerParent, 'is-end');
        }
        else {
          module.removeClass(containerParent, 'is-end');
        }
      };
    }
  };

  module.isTblWrapped = (tbl) => {
    if (tbl.parent.classList.contains('e-tbl__inner')) {
      return true;
    }

    return false;
  };

  module.isValidTbl = (tbl) => {
    if (tbl.table.classList.contains('no-tbl-scrollable')) {
      return false;
    }

    return true;
  };

  module.getEleContentWidth = (ele) => {
    let styles = window.getComputedStyle(ele),
      padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);

      return ele.clientWidth - padding;
  };

  module.isTblOverflow = (tbl) => {
    let tblWidth = tbl.table.clientWidth,
      parentInnerWidth = module.getEleContentWidth(tbl.parent);

    if (tblWidth > parentInnerWidth) {
      return true;
    }

    return false;
  };

  module.unwrapTbl = (tbl) => {
    // Quit, if table is not wrapped
    if (!module.isTblWrapped(tbl)) {
      return;
    }

    // find table outer wrapper
    let tblOuterWrapper = tbl.parent.parentElement;

    // Quit, if the table is not correctly wrapped
    if (!tblOuterWrapper.classList.contains('e-tbl__wrapper') ||
      !tbl.parent.classList.contains('e-tbl__inner')) {
      return;
    }

    // Find table caption, create new element
    let tblCaption = tblOuterWrapper.querySelectorAll('.e-tbl__caption')[0],
      newTbl = document.createElement('table');

    // Clone classes from original table to new one
    module.cloneClassList(tbl.table, newTbl);

    // If table has caption, insert caption into newly created table node
    if (tblCaption) {
      tblCaption = `<caption>${tblCaption.innerHTML}</caption>`;
      newTbl.insertAdjacentHTML('afterbegin', tblCaption);
    }

    // Insert table content into newly created table node
    newTbl.insertAdjacentHTML('beforeend', tbl.table.innerHTML);
    // Append new node in front of wrapped table, and remove wrapped one
    tblOuterWrapper.parentElement.insertBefore(newTbl, tblOuterWrapper);
    tblOuterWrapper.parentElement.removeChild(tblOuterWrapper);
  };

  module.wrapTbl = (tbl) => {
    // Create outer wrapper element and new html content
    let tblCaption = tbl.table.getElementsByTagName('caption')[0],
      tblOuterWrapper = document.createElement('div'),
      newInnerHTML = `<div class="e-tbl__inner">${tbl.table.outerHTML}</div>`;

    // If table has a caption
    // Put table caption outside inner wrapper so that it would stay in viewport
    if (tblCaption) {
      // remove original caption
      tbl.table.removeChild(tblCaption);
      // put caption content in '.e-tbl__caption' container
      newInnerHTML = `<div class="e-tbl__caption">${tblCaption.innerHTML}</div>
        <div class="e-tbl__inner">${tbl.table.outerHTML}</div>`;
    }

    // Insert wrapped table before original table, and remove original one
    tblOuterWrapper.className = 'e-tbl__wrapper';
    tbl.parent.insertBefore(tblOuterWrapper, tbl.table);
    tblOuterWrapper.innerHTML = newInnerHTML;
    tbl.parent.removeChild(tbl.table);
  };

  module.handleResponsiveTbls = (event) => {
    // Find all tables
    const tbls = document.querySelectorAll('table');

    // Stop if there were no tables inside html document
    if (!tbls) {
      return;
    }

    // Check tables
    for (let i = 0; i < tbls.length; i += 1) {
      // let tbl = tbls[i],
      //   tblParent = tbl.parentElement;
      let tbl = {
        'table': tbls[i],
        'parent': tbls[i].parentElement
      };

      // Skip invalid tables
      if (!module.isValidTbl(tbl)) {
        continue;
      }

      // Table overflows and not wrapped, needs to be wrapped
      if (module.isTblOverflow(tbl) && !module.isTblWrapped(tbl)) {
        module.wrapTbl(tbl);
      }

      // Table not overflow and wrapped, needs to be unwrapped
      if (!module.isTblOverflow(tbl) && module.isTblWrapped(tbl)) {
        module.unwrapTbl(tbl);
      }
    }

    // Show/Hide right end gradient mask by adding/removing class
    module.isTblScrolledToRight();
  };

  module.init = function() {
    // Make tables responsive
    window.addEventListener('load', function() {
      module.handleResponsiveTbls('load');
    });

    window.addEventListener('resize', debounce(() => {
      module.handleResponsiveTbls('resize');
    }, 200)
    );
  };

  return module;
})( $.Sq.Table || {} );