/**
 * @file    Gallery initialisation
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    Apr 2018
 */

(function($) {
    const win = typeof window === 'undefined' ? {} : window;

    if (!win.document) {
        return;
    }

    const SVG_URl = 'styles/mysource_files/sprite.svg';
    const SVG_PREV = 'arrow-left';
    const SVG_NEXT = 'arrow-right';

    const SELECTOR = '.c-gallery';

    const config = {
        dots:      true,
        infinite:  true,
        speed:     500,
        fade:      true,
        cssEase:   'cubic-bezier(.77, 0, .175, 1)',
        prevArrow: `<button type="button" class="slick-prev">
            <span class="visuallyhidden">Previous</span>
            <svg class="c-icon">
                <use xlink:href="${SVG_URl}#${SVG_PREV}"></use>
            </svg>
        </button>`,
        nextArrow: `<button type="button" class="slick-next">
            <span class="visuallyhidden">Next</span>
            <svg class="c-icon">
                <use xlink:href="${SVG_URl}#${SVG_NEXT}"></use>
            </svg>
        </button>`,
    };

    $(SELECTOR).each(function() {
        $(this).slick(config);
    })

})($);
