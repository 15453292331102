/*module*/
(function() {
  const applyVideoWrapper = () => {
    const respIframes = document.querySelectorAll('iframe.c-responsive-video');

    if(!respIframes) return;

    for(let i=0; i<respIframes.length; i++) {
      let respiframe = respIframes[i],
          wrapper = document.createElement('div');

      respiframe.parentNode.insertBefore(wrapper,respiframe);
      wrapper.appendChild(respiframe);
      wrapper.classList.add('c-video');
    }
  }

  const init = () => {
    applyVideoWrapper();
  }

  init();
})();