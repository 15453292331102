/* moduel */
(function() {
  const win   = window,
        html  = document.querySelector('html');

  const applyObjectfit = () => {
    // sellect all element with class c-objectfit;
    const objectFits = document.querySelectorAll('.c-objectfit');
    //check if html tag contains object-fit
    if(!html.classList.contains('object-fit')) {

      if(!objectFits) return;

      for(let i=0; i<objectFits.length; i++) { 
        let objectFit  = objectFits[i],
          container  = document.createElement('div'),  // create new wrapper element which could be difined by developer
          fitWraper  = document.createElement('div'),
          imgWidth   = objectFit.width,
          imgHeight  = objectFit.height,
          imgUrl     = objectFit.getAttribute('src');

        if(imgUrl) { 
          container.style.backgroundImage = 'url(' + imgUrl + ')';
          container.classList.add('compat-object-fit');
          fitWraper.classList.add('compat-object-fit-wrapper');
          objectFit.parentNode.appendChild(container).appendChild(objectFit);
          container.parentNode.appendChild(fitWraper)
                              .appendChild(container);
          fitWraper.style.width = imgWidth + 'px';
          fitWraper.style.height = imgHeight + 'px';
          fitWraper.style.display = 'inline-block';
          objectFit.style.display = 'block';
        };
      };    
    } else {
      for(let i=0; i<objectFits.length; i++) {
        let objectFit = objectFits[i],
                 aTag = objectFit.parentNode;
        if(aTag.nodeName.toLowerCase() === 'a' && aTag.href !== "") {
          aTag.classList.add('c-objectfit__wrapper');
        };
      };
    }
  }

  const init = () => {
    applyObjectfit();
  }

  init();
})();