$(".c-popup .c-popup__trigger").each(function() {
  $(this).magnificPopup({
    items: {
      src: $(this).next(".c-popup__box"),
      type: "inline",
      midClick: true
    },
    fixedContentPos: false
  });
});

$(".c-popup__box .c-btn--cancel").click(function() {
  $.magnificPopup.close();
});
