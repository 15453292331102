/**
 * @file    init the slick slide on ipad and mobile
 *          social media section tab bar
 *
 * @author  Mars Xu<mxu@squiz.net>
 * @date    Apr 2018
 */

$.Sq = $.Sq || {};

$.Sq.SocialTab = (function( module ) {
  module.toggleTab = function() {
    const $tabBtn       = $('.js-social__link'),
          $tabItems     = $('.p-social__icon-item'),
          $tabSection   = $('.p-social__inner-box');

    $tabBtn.on('click', function(e) {
      e.preventDefault();

      let $this       = $(this),
          $thisItem   = $this.parent(),
          $thisTarget = $($this.attr('href'));
      $tabItems.removeClass('is-tab-active');
      $thisItem.addClass('is-tab-active');

      $tabSection.removeClass('is-tab-active');
      $thisTarget.addClass('is-tab-active');

      // ARIA
      $tabBtn.attr('aria-selected', false);
      $this.attr('aria-selected', true);

      $tabSection.attr('aria-hidden', true);
      $thisTarget.attr('aria-hidden', false);
    });
  };

  module.init = function() {
    module.toggleTab();
  };

  return module;
})( $.Sq.SocialTab || {} );

// control slick slider by responsive;
(function(){
  const win = typeof window === 'undefined' ? {} : window;

  if(!win.document) {
    return;
  }

  const $sliders = $('.js-slider'),
        $window = $(window);

  if(!$sliders || $sliders == null) {
    return;
  }

  const activeSlider = function() {
    $sliders.not('.slick-initialized').slick({
      dots: true,
      infinite: false,
      speed: 100,
      slidesToShow: 1,
      centerMode: true,
      prevArrow: false,
      nextArrow: false,
      centerMode: true,
      variableWidth: true,
      responsive: [{
        breakpoint: 2048,
        settings: "unslick" // destroys slick
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: false,
          speed: 100,
          slidesToShow: 1,
          centerMode: true,
          prevArrow: false,
          nextArrow: false,
          centerMode: true,
          variableWidth: true
        }
      }
      ]
    });
  };

  const init = function() {
    if($window.width() < 1024) {
      activeSlider();
    };
  };

  const resizeSlider = function() {
    $(window).resize(function(){
      if($window.width() < 1024) {
        activeSlider();
      };
    });
  };


  init();
  resizeSlider();
})();
