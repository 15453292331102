/**
 * @file    hide/show chatbot 
 * 
 *
 * @author  mars xu
 * @date    Sep 2018
 */


(function( $ ) {
  const $activeBtn  = $('.js-chatbot'),
        $chatbotParent  = $activeBtn.parent().parent(),
        activeClass = 'active',
        $chatbot    = $('.c-chatbot');

  $activeBtn.on('click', function(e) {
      e.preventDefault();
      $(this).toggleClass(activeClass);
      $chatbotParent.find('.c-chatbot').slideToggle();
  });
  
})(jQuery);