/**
 * @file    init the side navigation
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    Apr 2018
 */

$.Sq = $.Sq || {};

$.Sq.Sidenav = (function() {
  const win = typeof window === "undefined" ? {} : window;

  if (!win.document) {
    return;
  }

  const selector =
    ".c-side-nav .c-accordion__title h1," +
    " .c-side-nav .c-accordion__title h2," +
    " .c-side-nav .c-accordion__title h3," +
    " .c-side-nav .c-accordion__title h4," +
    " .c-side-nav .c-accordion__title h5," +
    " .c-side-nav .c-accordion__title h6";

  const LINK_CLASS = "c-accordion__title";
  const SPAN_CLASS = "c-side-nav__level-1-text";
  const SPAN_HREF_ATTR_NAME = "data-href";

  /**
   * searching upward for parent that first match the given selector
   *
   * @param node
   * @param className
   * @return {HTMLElement}
   * @private
   */
  const _findParentByClassName = (node, className) => {
    let parent = node.parentNode;

    while (!parent.classList.contains(className)) {
      parent = parent.parentNode;
    }

    return parent;
  };

  /**
   * Get href of a node
   *
   * @param node
   * @return {string}
   * @private
   */
  const _getLink = node => (node ? node.getAttribute("href") : "#");

  /**
   * wrapped text span's click handler
   * @param e
   */
  const _spanClickHandler = e => {
    e.preventDefault();

    const link = e.target.getAttribute(SPAN_HREF_ATTR_NAME);
    if (!link || e.buttons !== 1) {
      return;
    }
    win.location = link;
  };

  /**
   * wrap node's text content
   * @param node
   * @return {HTMLSpanElement}
   */
  const _wrapInnerText = node => {
    // find closest parent which carries the href value
    const parent = _findParentByClassName(node, LINK_CLASS);

    // get the href value
    const dataHref = _getLink(parent);

    // create the wrapped text content, set attributes and add event handler
    const spanNode = win.document.createElement("span");

    spanNode.className = SPAN_CLASS;
    spanNode.textContent = node.textContent;
    spanNode.setAttribute(SPAN_HREF_ATTR_NAME, dataHref);
    spanNode.setAttribute("tabindex", "0");

    spanNode.addEventListener("mousedown", _spanClickHandler);

    return spanNode;
  };

  /**
   * Replace node's textContent with a wrapped textContent
   * @param node
   */
  const replaceTextNode = node => {
    const textSpan = _wrapInnerText(node);

    node.textContent = "";
    node.appendChild(textSpan);
  };

  /**
   * Initialisation
   */
  const init = () => {
    const targets = win.document.querySelectorAll(selector);

    Array.prototype.slice.call(targets).forEach(node => {
      replaceTextNode(node);
    });
  };

  return {
    init
  };
}());
