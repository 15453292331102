/**
 * @file    tools, e.g. print button, download button
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    May 2018
 */

$.Sq = $.Sq || {};

$.Sq.Tools = (function() {
    const win = typeof window === 'undefined' ? {} : window;

    if (!win.document) {
        return;
    }

    const defaultConfig = {
        printSelector:       '.c-tools__link--print',
        //downloadSelector:    '.c-tools__link--download',
        copySelector:        '.c-tools__link--copy',
        copiedClass:         'u-copied',
        copyErrorClass:
            'u-error',
        copyIconDisplayTime: 3000, // the time of showing copy's success/error icon
    };

    /**
     * print button's click handler
     * @param e

    const printClickHandler = (e) => {
        e.preventDefault();

        win.print();
    };*/

    /**
     * download button's click handler
     * @param e

    const downloadClickHandler = (e) => {
        e.preventDefault();

        win.print(); // todo: enhance download as pdf functionality
    };*/

    /**
     * Add className, then remove it after a while
     * @param el
     * @param className
     * @param timer
     */
    const temporarilyAddClass = (el, className, timer = 0) => {
        el.classList.add(className);

        setTimeout(() => {
            el.classList.remove(className);
        }, timer);
    };

    /**
     * copy button's copy success handler
     * @param config
     * @param e
     */
    const copySuccessHandler = (config, e) => {
        temporarilyAddClass(e.trigger, config.copiedClass, config.copyIconDisplayTime);
    };

    /**
     * copy button's copy failed handler
     * @param config
     * @param e
     */
    const copyFailedHandler = (config, e) => {
        temporarilyAddClass(e.trigger, config.copyErrorClass, config.copyIconDisplayTime);
    };

    /**
     * Init clipboard.js instance
     * @param config
     * @param config.copySelector
     */
    const initClipboard = (config) => {
        if (typeof ClipboardJS === 'undefined') {
            console.warn('ClipboardJS library not found. Copy links are not functioning.');
            return;
        }

        const clipboard = new ClipboardJS(config.copySelector);

        clipboard.on('success', copySuccessHandler.bind(null, config));
        clipboard.on('error', copyFailedHandler.bind(null, config));
    };

    const init = (customConfig) => {
        const config = Object.assign({}, defaultConfig, customConfig);

        /*Array.prototype.slice.call(win.document.querySelectorAll(config.printSelector)).forEach((element) => {
            element.addEventListener('click', printClickHandler);
        });*/

        /*Array.prototype.slice.call(win.document.querySelectorAll(config.downloadSelector)).forEach((element) => {
            element.addEventListener('click', downloadClickHandler);
        });*/

        initClipboard(config);
    };

    return {
        init,
    };
})();
