function initialiseDecisionTree() {

	const apiCalculateEndpoint = 'https://np-dit-tu-rules-as-code-appservice.azurewebsites.net/calculate';
	const formMarkupContainer = 'js-api-content-container';
	const loadingMessageContainer = 'js-load-message';
	var apiRequestQueueCurrent = $.Deferred();
	let apiRequestQueueCount = 0;
	let apiResponseData = [];
	const squizAppDecisionTreeData = {
        "gaming_activity_type": {
            "art_union_gaming_activity": [
                "total_prize_value_of_all_prizes_from_gaming_activity",
                "gross_proceeds_from_gaming_activity",
                "proceeds_to_benefiting_organisation",
                "money_payable_as_separate_prize",
                "raising_funds_for_charitable_purpose",
                "raising_funds_for_non_profit_purpose",
                "gaming_activity__has_charitable_nature"
            ],
            "charity_housie": [
            	"charitable_purpose",
            	"gross_proceeds_from_gaming_activity",
            	"total_prize_value_from_single_gaming_session",
                "proceeds_to_benefiting_organisation",
                "total_expenses_for_conducting_gaming_activity",
                "number_of_tickets"
            ],
            "social_housie": [
                "gaming_activity_solely_for_social_purposes",
                "venue_is_licensed_premises",
                "net_proceeds_returned_to_participants",
                "value_of_jackpot_prize",
                "total_prize_value_from_single_gaming_session"
            ],
            "club_bingo": [
                "total_prize_value_from_single_gaming_session",
                "value_of_bonus_prize",
                "prize_consists_of_money",
                "gaming_activity_on_authority_of_reg_club",
                "gaming_activity_organised_for_patronage",
                "venue_is_registered_club"
            ],
            "draw_lottery": [
                "charitable_or_non_profit_purpose",
                "gross_proceeds_from_gaming_activity",
                "proceeds_to_benefiting_organisation",
                "total_prize_value_of_all_prizes_from_gaming_activity"
            ],
            "no_draw_lottery": [
                "charitable_or_non_profit_purpose",
                "total_prize_value_of_all_prizes_from_gaming_activity",
                "gross_proceeds_from_gaming_activity",
                "proceeds_to_benefiting_organisation",
                "number_of_tickets"
            ],
            "mini_numbers_lottery": [
                "charitable_or_non_profit_purpose",
                "gross_proceeds_from_gaming_activity",
                "proceeds_to_benefiting_organisation",
                "total_prize_value_from_single_gaming_session"
            ],
            "progressive_lottery": [
                "money_paid_as_prize",
                "total_prize_value_of_all_prizes_from_gaming_activity"
            ],
            "free_lottery": [
                "total_prize_value_of_all_prizes_from_gaming_activity",
                "free_participation",
                "prize_consists_of_money"
            ],
            "promotional_raffle": [
                "gaming_activity_on_authority_of_reg_club",
                "venue_is_registered_club",
                "gaming_activity_organised_for_patronage",
                "gross_proceeds_from_gaming_activity",
                "proceeds_used_for_meeting_cost_of_prizes",
                "total_prize_value_from_single_gaming_session",
                "prize_consists_of_money"
            ],
            "trade_promotion": [
                "participation_fees",
                "business_consent",
                "total_prize_value_of_all_prizes_from_gaming_activity"
            ],
            "sweep_or_calcutta": [ // Note: Some of these questions are not displayed, see the logic below in onNextButtonClick()
            	"fees_for_participation",
            	"total_prize_value_of_all_prizes_from_gaming_activity",
            	"sweep_or_calcutta__is_social_game",
            	// If sweep_or_calcutta__is_social_game = true, ask this next question
            	"all_gross_proceeds_are_distributed_to_participants_based_on_stake_held",
            	// If sweep_or_calcutta__is_social_game = false, ask these questions
            	"organisation_type",
            	"reasonable_amount_to_benefiting_organisation",
            	"amount_agreed_in_writing_beforehand"
            ],
            "other_gaming_activity": [
            	"charitable_purpose",
                "gross_proceeds_from_gaming_activity",
                "proceeds_to_benefiting_organisation",
                "total_prize_value_from_single_gaming_session"
            ]
        }
    };
	let apiRequestQuestionIDs = [];
	let apiTraceDataComplete = {};

	/**
	 * Events
	 */

	// Hijack attempts to submit the form. The form is only used for accessible controls
	$('.js-rebate-form').on('submit', function(evt){
		// Prevent the form submission
		evt.preventDefault();
	});

	//On change remove error message
	$(".decision-form").on("change", "input", function () {
	    $(this).parents(".decision-question__content").find(".error").fadeOut("slow");
	});

	//Display radio label description
	$(".decision-form").on( "click", ".question-label__icon-help", function(e) {
	  var label_description = $(this).parent().next(".question-label__description");
	  if (label_description.length) {
	    $(this).hide();
	  }
	  label_description.slideDown("slow");
	});

	//Hide radio label description
	$(".decision-form").on( "click", ".question-label__description button", function() {
	  $(this).parent().slideUp("slow");
	  $(this).parent().prev().find(".question-label__icon-help").fadeIn("slow");
	});

	//on clicking next button
	$( ".decision-form" ).on( "click", ".js-next-question", onNextButtonClick);

	//Edit any previous answer
	$(".decision-form").on( "click", ".decision-question__answer button", function(e) {
		e.preventDefault();
		var current_answer = $(this).parent();
		// Disable the submit button
		$(".decision-form__submit").attr('disabled', 'disabled');
		current_answer.hide();
		current_answer.prev().fadeIn("slow");
		$(".decision-form__hr").slideUp("slow");
		$(".decision-form__submit").slideUp("slow");

		// Hide all questions after the currently selected one.
		// Also re-expand the already answered questions.
		$(this).parent().parent().nextUntil('.decision-form__hr').each(function() {
			$(this).attr('style', 'display: none;');
			$(this).children('.decision-question__answer').hide();
			$(this).children('.decision-question__content').show();
		});
	});

	//Back button
	$(".decision-message__back").on( "click", function() {
	  var submission_summary = $(this).parent();
	  submission_summary.slideUp("slow");
	  submission_summary.prev().fadeIn("slow");
		// Hide the "Apply" section and button
		// $('.js-permit-required-content').hide();
	});

	//Decision tree form submission
	$(".decision-form__submit").on( "click", onFormSubmission );

	// Show the loading message
	// $('.' + loadingMessageContainer).addClass('is-active');

	// Initialise. Get and build the decision tree questions from the API
	// getGamingTypeQuestions(apiVariableEndpoint, onDecisionTreeBuildComplete);


	/**
	 * Methods
	 */

	/**
	 * Respond to the decision tree JSON being been created
	 * Generate the markup for the first question (gaming_activity_type)
	 */
	function onDecisionTreeBuildComplete() {
		let questionMarkup =
		`<div class="decision-question">
			<h3>Type of game</h3>
			<div id="gaming_activity_type" class="js-first-question decision-question__content question_radio">
				<h4 class="question-heading">What type of game are you running?</h4>`;

		Object.keys(squizAppDecisionTreeData.gaming_activity_type).forEach(key => {
			questionMarkup +=
				`<label class="radio-label" for="${key}">${convertIDToHumanReadable(key)}
					<input type="radio" id="${key}" name="gaming_activity_type" data-post-exclude="false" value="${key}" data-name="${convertIDToHumanReadable(key)}" required="">
					<span class="checkmark"></span>
				</label>`;
		});

		questionMarkup +=
				`<hr>
				<button class="js-next-question c-btn c-btn--round c-btn--border-inverse u-no-margin print-no" type="button">Next</button>
				<div class="error print-no"></div>
			</div>
			<div class="decision-question__answer">
				<button type="button" title="Edit your response" class="print-no">
					<svg class="edit-icon">
						<use xlink:href="./?a=334830:matrix-files/styles/mysource_files/sprite.svg#angle-down"></use>
					</svg>
				</button>
			</div>
		</div>`;

		$(questionMarkup).insertBefore( ".decision-form__hr" );

		// Hide the loading message
		$('.' + loadingMessageContainer).removeClass('is-active');
	}

	function getGamingTypeQuestions(apiEndpoint, callback) {
		const XHR = new XMLHttpRequest();

		apiTraceDataComplete = callback;

		// Respond to requests to POST the form
		XHR.addEventListener('load', onGetGamingTypeRequest);

		// Respond to request errors
		XHR.addEventListener('error', onGetGamingTypeRequest);

		// Set up our request
		XHR.open("GET", apiEndpoint + '?variableID=gaming_activity_type');

		// Make the request
		XHR.send();
	}

	/**
	 * Respond to the Gaming Type question request
	 *
	 * @param {ProgressEvent} evt The event as part of the XHR request
	 *
	 * @returns void
	 */
	function onGetGamingTypeRequest(evt) {
		let response = {};

		// Parse the API response
		try {
			response = JSON.parse(evt.currentTarget.response);
		} catch (error) {
			console.log('Error. Invalid response from API. Expecting JSON. ', evt.currentTarget.response);

			// Hide the loading message
			$('.' + loadingMessageContainer).removeClass('is-active');

			// Add an error message
			$('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Failed to load question from API. Please reload the page and try again.');
			return;
		}

		// Add the gaming_type IDs to our tree of options
		Object.keys(response.possibleValues).forEach(key => {
			// Capture the IDs needed for the trace requests
			apiRequestQuestionIDs.push(key);
		});

		// Get the trace data for the first question ID
		getTraceData(apiRequestQuestionIDs.shift(), apiTraceEndpoint);
	}

	function getTraceData(id, apiEndpoint) {
		const XHR = new XMLHttpRequest();
		const postBody = {
			"persons": {
				"_": {}
			},
			"organisations": {
				"treeApp": {
					"representatives": ["_"],
					"gaming_activity_result": {
						"2020-09": null
					},
					"gaming_activity_result_str": {
						"2020-09": null
					},
					"gaming_activity_authority_fee_str": {
						"2020-09": null
					},
					"gaming_activity_type": {
						"2020-09": ""
					}
				}
			}
		};

		postBody.organisations.treeApp.gaming_activity_type["2020-09"] = id;

		// Respond to requests to POST the form
		XHR.addEventListener('load', function(evt) {
			onGetTraceDataRequest(id, evt);
		});

		// Respond to request errors
		XHR.addEventListener('error', function(evt) {
			onGetTraceDataRequest(id, evt);
		});

		// Set up our request
		XHR.open("POST", apiEndpoint);

		// Add the JSON type HTTP request header
		XHR.setRequestHeader('content-type', 'application/json');

		// Make the request
		XHR.send(JSON.stringify(postBody));
	}

	/**
	 * Respond to the trace request
	 *
	 * @param {String} id The ID of the gaming_type that we "tracing"
	 * @param {ProgressEvent} evt The event as part of the XHR request
	 *
	 * @returns void
	 */
	function onGetTraceDataRequest(id, evt) {
		let response = {};

		// Add the gaming_activity_type ID to the tree
		squizAppDecisionTreeData.gaming_activity_type[id] = [];

		// Parse the API response
		try {
			response = JSON.parse(evt.currentTarget.response);
		} catch (error) {
			console.log('Error. Invalid response from API. Expecting JSON. ', evt.currentTarget.response);

			// Hide the loading message
			$('.' + loadingMessageContainer).removeClass('is-active');

			// Add an error message
			$('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Failed to load question from API. Please reload the page and try again.');
			return;
		}

		// Check if we got the trace data back
		if (response.trace === undefined) {
			console.log(`Error. Invalid ID of "${id}" sent to API. Expecting trace data. `, response);

			// Hide the loading message
			$('.' + loadingMessageContainer).removeClass('is-active');

			// Add an error message
			$('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Failed to load question from API. Please reload the page and try again.');
			return;
		}

		// Iterate over the trace response
		Object.keys(response.trace).forEach(key => {

			// Questions we need to ask the user must meet the below criteria:

			// Have no dependencies
			if (response.trace[key].dependencies.length > 0) {
				return;
			}

			// The questions should not be "return_type" or "gaming_activity_type"
			if (key.match(/return_type|gaming_activity_type|__authority_required/)) {
				return;
			}

			// Remove the calculation date part from the question ID (e.g. "<2020-09>") and store the ID
			squizAppDecisionTreeData.gaming_activity_type[id].push(key.replace(/<.*$/, ''));
		});

		// Check if there are any more gaming_type IDs to trace
		if (apiRequestQuestionIDs.length > 0) {

			// Get the trace data for the next question ID
			getTraceData(apiRequestQuestionIDs.shift(), apiTraceEndpoint);
		} else {

			// All trace data received, call the callback
			apiTraceDataComplete();
		}
	}

	/**
	 * Handle input validation and displaying the next question
	 *
	 * @param {jQuery Event} evt jQuery Event Object
	 */
	function onNextButtonClick(evt) {

		var current_question = $(this).parent();
		var next_question = current_question.parent().next('.decision-question');
		var error_box = $(this).next(".error");
		var answer = "";
		var answerDenomination = "";

		evt.preventDefault();

		// Check if the current question is "sweep_or_calcutta__is_social_game"
		// If the answer to that question is "No", then we skip the next question
		// Note: This is darned ugly but a temporary solution was required
		if (current_question.find('input').attr('name') === "sweep_or_calcutta__is_social_game" && current_question.find(":checked").data("name") === "No") {
			next_question = current_question.parent().next('.decision-question').next('.decision-question');
		}

		// Check if the current question is "all_gross_proceeds_are_distributed_to_participants_based_on_stake_held"
		// If it is, then we skip the remaining questions and show the submit button
		if (current_question.find('input').attr('name') === "all_gross_proceeds_are_distributed_to_participants_based_on_stake_held") {
			next_question = [];
		}

		// Remove the current answer (e.g. if user is editing a question they already answered)
		current_question.next(".decision-question__answer").find("p").remove();

		if ($(current_question).hasClass('question_radio')) {
		    if ($(current_question).find(':checked').length <= 0) {
		        $(error_box).html("You must select one of the above options");
		        $(error_box).fadeIn("slow");
		        return false;
		    }
			answer = current_question.find(":checked").data("name");
			current_question.next(".decision-question__answer").prepend( "<p>" + answer + "</p>" );

		} else if ($(current_question).hasClass('question_number')) {
		    if ($(current_question).find('input').val() == "") {
			    $(error_box).html("You must enter a value for this question");
			    $(error_box).fadeIn("slow");
			    return false;
			}
			answer = $(current_question).find('input').val();

			// Selectively prepend a symbol to the response
			if (!$(current_question).hasClass('sans-symbol')) {
				answerDenomination = "$";
			}
			current_question.next(".decision-question__answer").prepend( "<p>" + answerDenomination + answer + "</p>" );
		} else {
			alert("Invalid question");
			return false;
		}

		// Submit the tracking data to GTM
		sendGTMData('questionAnswered', current_question.find('input').attr('name'), answer);

		// If this is the first question, then we need to use it to get the content from the API
		// The value of the selected option should match that of an Object in the "gaming_activity_type" options in the "squizAppDecisionTreeData" global variable that is printed on the page
		if (current_question.hasClass('js-first-question')) {
			// Remove any current questions that have been loaded from the API
			$('.js-api-question').remove();

			// Show the loading message
			$('.' + loadingMessageContainer).addClass('is-active');

			// Get the question content from the API
			getQuestionContentFromAPI(current_question.find(":checked").val(), apiVariableEndpoint);
			return false;
		}

		// Show submit button or animate to the next question.
		if (next_question.length === 0) {
			// No next question, so show the submit button and enable it
			current_question.slideUp("slow");
			current_question.next(".decision-question__answer").fadeIn("slow");
			$(".decision-form__hr").fadeIn("slow");
			$(".decision-form__submit").fadeIn("slow");
			$(".decision-form__submit").removeAttr('disabled');
		} else {
			// Show the next question
			next_question.removeAttr('style');
			current_question.next(".decision-question__answer").fadeIn("slow");
			current_question.slideUp("slow", function() {
				try {
					//scroll next question to top
					var next_question_title = next_question.find('h3')[0];
					$('html, body').animate({ scrollTop: $(next_question_title).offset().top - 20 }, 'slow');
				} catch(err) {}
			});
		}
	}

	function onContentRequestComplete() {

		let allQuestionMarkup = [];

		if (apiResponseData.length === 0) {
			// No data in response
			console.log('No decision tree branches were found for the requested Variable ID. Check "squizAppDecisionTreeData".');
			$('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Failed to find questions for that gaming type. Please reload the page and try again.');
		}

		// console.log('FINISHED!', apiResponseData);

		// Convert the JSON to HTML
		for (var i = 0, j = apiResponseData.length; i < j; ++i) {
			allQuestionMarkup.push(generateQuestionMarkup(apiResponseData[i]));
		}

		// Reset the JSON data (e.g. for in case we need to make another content request)
		apiResponseData = [];

		// Hide the loading message
		$('.' + loadingMessageContainer).removeClass('is-active');

		// Add markup to the page
		$(allQuestionMarkup.join('\n')).insertBefore( ".decision-form__hr" );

		// Make the next question visible
		$('.js-first-question').parent().next().removeAttr('style');

		// Show the answer section
		$('.js-first-question').next(".decision-question__answer").fadeIn("slow");

		// Animate to the next question
		$('.js-first-question').slideUp("slow", function() {
			try {
				//scroll next question to top
				var next_question = $('.js-first-question').parent().next().find('h3')[0];
				$('html, body').animate({ scrollTop: $(next_question).offset().top - 20 }, 'slow');
			} catch(err) {}
		});
	}

	function generateQuestionMarkup(questionAsJSON) {
		let questionMarkup = '';

		switch (questionAsJSON.valueType) {
			case "Boolean":
				// Markup for Yes/No questions
				questionMarkup = `<div id="${questionAsJSON.id}" class="js-api-question decision-question" style="display: none;">
					<h3>${convertIDToHumanReadable(questionAsJSON.id)}</h3>
					<div class="decision-question__content question_radio">
						<h4 class="question-heading" for="${questionAsJSON.id}">${questionAsJSON.description}</h4>
						${parseReferencesContent(questionAsJSON)}
						<label class="radio-label radio-bollean" for="${questionAsJSON.id}_yes">Yes
							<input type="radio" id="${questionAsJSON.id}_yes" name="${questionAsJSON.id}" data-post-exclude="false" value="true" data-name="Yes" required="">
							<span class="checkmark"></span>
						</label>
						<label class="radio-label radio-bollean" for="${questionAsJSON.id}_no">No
							<input type="radio" id="${questionAsJSON.id}_no" name="${questionAsJSON.id}" data-post-exclude="false" value="false" data-name="No" required="">
							<span class="checkmark"></span>
						</label>
						<hr>
						<button class="js-next-question c-btn c-btn--round c-btn--border-inverse u-no-margin print-no" type="button">Next</button>
						<div class="error print-no"></div>
					</div>
					<div class="decision-question__answer">
						<button type="button" title="Edit your response" class="print-no">
							<svg class="edit-icon">
								<use xlink:href="./?a=334830:matrix-files/styles/mysource_files/sprite.svg#angle-down"></use>
							</svg>
						</button>
					</div>
				</div>`;
				break;

			case "Int":
				// Markup for number input questions
				questionMarkup = `<div id="${questionAsJSON.id}" class="js-api-question decision-question" style="display: none;">
					<h3>${convertIDToHumanReadable(questionAsJSON.id)}</h3>
					<div class="decision-question__content question_number ${generateAdditionalClasses(questionAsJSON.id)}">
						<h4 class="question-heading" for="${questionAsJSON.id}">${questionAsJSON.description}</h4>
						${parseReferencesContent(questionAsJSON)}
						<span>
							<input type="number" id="${questionAsJSON.id}" name="${questionAsJSON.id}" data-post-exclude="false" placeholder="">
						</span>
						<button class="js-next-question c-btn c-btn--round c-btn--border-inverse u-no-margin print-no" type="button">Next</button>
						<div class="error print-no"></div>
					</div>
					<div class="decision-question__answer">
						<button type="button" title="Edit your response" class="print-no">
							<svg class="edit-icon">
								<use xlink:href="./?a=334830:matrix-files/styles/mysource_files/sprite.svg#angle-down"></use>
							</svg>
						</button>
					</div>
				</div>`;
				break;

			case "String":
				// Markup for radio input questions
				questionMarkup = `<div id="${questionAsJSON.id}" class="js-api-question decision-question" style="display: none;">
					<h3>${convertIDToHumanReadable(questionAsJSON.id)}</h3>
					<div class="decision-question__content question_radio">
						<h4 class="question-heading" for="${questionAsJSON.id}">${questionAsJSON.description}</h4>
						${parseReferencesContent(questionAsJSON)}
						${generateRadioOptions(questionAsJSON)}
						<hr>
						<button class="js-next-question c-btn c-btn--round c-btn--border-inverse u-no-margin print-no" type="button">Next</button>
						<div class="error print-no"></div>
					</div>
					<div class="decision-question__answer">
						<button type="button" title="Edit your response" class="print-no">
							<svg class="edit-icon">
								<use xlink:href="./?a=334830:matrix-files/styles/mysource_files/sprite.svg#angle-down"></use>
							</svg>
						</button>
					</div>
				</div>`;
				break;

			default:
				break;
		}

		function generateAdditionalClasses(questionID) {
			let additionalClasses = '';

			// Check if the input is a simple integer or a cost ($) value
			if (questionID === "number_of_tickets") {
				additionalClasses = 'sans-symbol';
			}

			return additionalClasses;
		}

		function getReferencesText(jsonData) {
			if (jsonData.references === undefined) {
				return '';
			}
			if (jsonData.references.length === 0) {
				return '';
			}
			return '<p>' + questionAsJSON.references[0] + '</p>';
		}

		function generateRadioOptions(jsonData) {
			let radioMarkup = [];

			if (jsonData.possibleValues === undefined) {
				return '';
			}

			for (var prop in jsonData.possibleValues) {
				radioMarkup.push(`<label class="radio-label" for="${prop}">${jsonData.possibleValues[prop]}
					<input type="radio" id="${prop}" name="${jsonData.id}" value="${prop}" data-name="${jsonData.possibleValues[prop]}" data-post-exclude="false" required>
					<span class="checkmark"></span>
				</label>`);
			}

			return radioMarkup.join('\n');
		}

		/**
		 * Convert the question's "references" data into HTML
		 * Note on the text formatting:
		 * See the legislation referencing guide here: https://answers.library.westernsydney.edu.au/faq/238047
		 *
		 * Example JSON:
		 * [..],
		 * "references":["{
			"identifier": "Community Gaming Regulation 2020",
			"version": "1 July 2020",
			"commencement": "26 June 2020",
			"part": {
				"identifier": "2",
				"part_type": "Part",
				"title": "Permitted gaming activities",
				"part": {
					"identifier": "9",
					"part_type": "Clause",
					"title": "Progressive lotteries"
				}
			}
		 * }"]
		 *
		 * @param {JSON} jsonData The JSON data for the question
		 *
		 * @returns String
		 */
		function parseReferencesContent(jsonData) {
			let referencesObject = {};
			let referencesMarkup = '';
			let linkText = '';
			let baseURL = 'https://legislation.nsw.gov.au/#/view/regulation/2020/304';
			let fullURL = '';

			// If the gaming ID is "total_prize_value_from_single_gaming_session" then we hardcode a message
			if (jsonData.id === "total_prize_value_from_single_gaming_session") {
				return '<p>*Session of a gaming activity means a number of games of the activity played in succession on the same occasion at the same place.</p>';
			}

			// Check that we have references data
			if (jsonData.references !== undefined) {
				if (jsonData.references.length !== 0) {

					// References data is not always JSON data
					try {
						// References data is also JSON encoded, so decode it
						referencesObject = JSON.parse(jsonData.references[0]);
					} catch(err) {
						// Do nothing.
						console.log('Unexpected references format for the question: ', jsonData, err);
					}
				}
			}

			if (referencesObject.identifier !== undefined && referencesObject.part !== undefined) {

				linkText += '<em>' + referencesObject.identifier + '</em> (NSW)';
				fullURL += baseURL;

				// Legislation "Part"
				if (referencesObject.part.part_type !== undefined) {
					linkText += ' pt ' + referencesObject.part.identifier;
					fullURL += '/part' + referencesObject.part.identifier;
				}

				// Legislation "Section"
				if (referencesObject.part.part.part_type !== undefined) {
					linkText += ' s ' + referencesObject.part.part.identifier;
					fullURL += '/sec' + referencesObject.part.part.identifier;
				}

				referencesMarkup = '<p>More information: <a href="' + fullURL + '" target="_blank">' + linkText + '</a></p>';
			}

			return referencesMarkup;
		}

		return questionMarkup;
	}

	/**
	 * After the user picks a "gaming_type" in the first question, we then need to get the list of questions to ask them next.
	 * From the decision tree JSON, we grab the Array of question IDs that match the selected gaming_type that the user picked.
	 * Then we create a queue of requests and synchronously get the content for each of those questions.
	 *
	 * @param {String} gamingTypeID The ID of the gaming_type to retrieve the questions for
	 * @param {String} apiEndpoint The URL to use when making the content request
	 */
	function getQuestionContentFromAPI(gamingTypeID, apiEndpoint) {
		let variableIDs = squizAppDecisionTreeData.gaming_activity_type[gamingTypeID];

		// Hide the API error message
		$('.js-api-error-message').remove();

		// Catch any errors with missing decision tree Variables
		if (variableIDs === undefined) {
			console.log('Unable to locate tree options for gaming type: ', gamingTypeID);

			// Hide the loading message
			$('.' + loadingMessageContainer).removeClass('is-active');

			// Add an error message
			$('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Failed to load question from API. Please reload the page and try again.');
			return;
		}

		if (apiRequestQueueCount === variableIDs.length) {
			// Queue completed
			onContentRequestComplete();

			// Reset the queue
			apiRequestQueueCount = 0;
			return;
		}

		// Reset the Deferred Object
		apiRequestQueueCurrent = $.Deferred();

		// Wait for the current request to be completed
		$.when(apiRequestQueueCurrent)
		.then(function(){
			// console.log('Question data retrieved.');
			getQuestionContentFromAPI(gamingTypeID, apiEndpoint);
		});

		// Make the next request to the API
		getVariableDataFromAPI(variableIDs[apiRequestQueueCount], apiEndpoint);
	}

	/**
	 * Get Variable (question) content from the API.
	 * This is used in conjunction with getQuestionContentFromAPI() in order retrieve question content from a queue of items
	 *
	 * @param {String} variableID The Variable ID to retrieve the content of
	 * @param {String} apiEndpoint The URL to use when making the content request
	 */
	function getVariableDataFromAPI(variableID, apiEndpoint) {
		$.ajax({
			url: apiEndpoint + '?variableID=' + variableID
		})
		.fail(function(data) {
			console.log('API Variable failed to load.', data);

			// Hide the loading message
			$('.' + loadingMessageContainer).removeClass('is-active');

			// Add an error message
			$('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Failed to load question from API. Please reload the page and try again.');
		})
		.done(function(data) {

			// console.log('DONE', data);

			// Confirm that the response is JSON data
			try {
				apiResponseData.push(JSON.parse(data));

				// Increase the queue counter
				++apiRequestQueueCount;

				// Resolve the current request
				apiRequestQueueCurrent.resolve();
			} catch(err) {
				console.log('Unexpected response format for API Variable.', err, data);

				// Hide the loading message
				$('.' + loadingMessageContainer).removeClass('is-active');

				// Add an error message
				$('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Failed to load question from API. Please reload the page and try again.');
			}
		});
	}

	function onFormSubmission(e) {
		//JSON structure to post to API
		var jsonObject = {
			"persons": {
				"_": {}
			},
			"organisations" : {
				"squizApp": {
					"representatives": ["_"],
					"gaming_activity_result": {
						"2020-09": null
					},
					"gaming_activity_result_str": {
						"2020-09": null
					},
					"gaming_activity_authority_fee_str": {
						"2020-09": null
					}
				}
			}
		};

		e.preventDefault();

		// Send the tracking data to GTM
		sendGTMData('formCompleted', '', 'Submit');

		// Show the loading message
		$('.' + loadingMessageContainer).addClass('is-active');

		var formArray = $(".decision-form.print-only").serializeArray();
		jQuery.each(formArray, function(index, item) {
		    var itemName = "[name="+ item.name +"]";
		    if($(itemName).data("post-exclude") == false) {
		        var itemObject = {"2020-09": parseItemValue(item.value)};
			    jsonObject.organisations.squizApp[item.name] = itemObject;
		    }
		});

		//Post request to API
	    $.ajax({
	      url: apiCalculateEndpoint,
	      method: 'POST',
	      dataType: 'json',
	      processData: false,
	      data: JSON.stringify(jsonObject),
	      contentType: 'application/json'
	    }).done(function(data) {
	      // console.log('Request completed: ', data);

	      // Print the response from the API
	      $(".js-decision-message-heading").html(data.organisations.squizApp.gaming_activity_result_str["2020-09"]);
	      // $(".js-decision-message-body").html(data.organisations.squizApp.gaming_activity_authority_fee_str["2020-09"]);

	      // Display the "Apply" section and button if a permit is required
	      // if (data.organisations.squizApp.gaming_activity_result["2020-09"] === 'permitted_with_authority') {
	      // 	$('.js-permit-required-content').show();
	      // }

	      // Reset the form summary section
	      $(".decision-message__content ol").empty();

	      // Loop over each response and present it in the summary section
	      jQuery.each(formArray, function(index, item) {
	        item.value = $($($(".decision-form").children("#" + item.name)).find(".decision-question__answer")[0]).find("p").text();

	          $(".decision-message__content ol").append(
	              '<li><span>' + $($(".decision-form").children("#" + item.name)).find("h3").text() + '</span><span class="hidden">:&nbsp;</span>' +
	              '<span>' + item.value  + '</span></li>'
	            );
		    });
		  $(".decision-form").slideUp("fast");
	      $(".decision-message").fadeIn("slow");
	      setTimeout(function(){
	        $('html, body').animate({ scrollTop: $(".decision-message__content").offset().top}, 'slow');
	      }, 600);
	    })
		.always(function(data, textStatus, jqXHR) {
			// Send a copy of the user's responses to a Matrix form
			// The URL and ID values should be printed into the page content
			submitJSONResponseToMatrix(decisionTreeFormURL, decisionTreeFormID, JSON.stringify(jsonObject));

			// Hide the loading message
			$('.' + loadingMessageContainer).removeClass('is-active');
		})
	    .fail(function(data) {
	      console.log('API Calculate request failed: ', data);
	      $('.' + formMarkupContainer).append('<p class="js-api-error-message">An error has occurred. <br>Unable to contact the API in order to calculate eligibility. Please reload the page and try again.');
	    });

	    function parseItemValue(value) {
	    	// Convert Boolean String values into actual Booleans
	    	if (value === 'false') {
				return false;
	    	}
	    	if (value === 'true') {
	    		return true;
	    	}
	    	return value;
	    }
	}

	function submitJSONResponseToMatrix(url, formID, json) {
	        const XHR = new XMLHttpRequest();
	        const formAction = url + '/_nocache';
	        const boundary = "blob"; // We need a separator to define each part of the request
	        let data = ""; // Store our body request in a string.

	        let formInputs = [
	            {
	                "name": 'SQ_FORM_' + formID + '_PAGE',
	                "value": '1'
	            },
	            {
	                "name": 'form_email_' + formID + '_submit',
	                "value": 'Submit'
	            },
	            {
	                "name": 'form_email_' + formID + '_referral_url',
	                "value": ''
	            },
	            {
	                "name": 'q' + formID + ':q1',
	                "value": JSON.stringify(json)
	            }
	        ];

	        for (var i = 0, j = formInputs.length; i < j; ++i) {
	            // Start a new part in our body's request
	            data += "--" + boundary + "\r\n";

	            // Say it's form data, and name it
	            data += 'content-disposition: form-data; name="' + formInputs[i].name + '"\r\n';

	            // There's a blank line between the metadata and the data
	            data += '\r\n';

	            // Append the input data to our body's request
	            data += formInputs[i].value + '\r\n';
	        }

	        // Once we are done, "close" the body's request
	        data += "--" + boundary + "--";

	        // Respond to requests to update the Matrix submission form
	        XHR.addEventListener('load', function(evt) {
	            // console.log('Success.', evt);
	        });

	        // Respond to request errors
	        XHR.addEventListener('error', function(evt) {
	            console.log('Oops! Something went wrong.', evt);
	        });

	        // Set up our request
	        XHR.open('POST', formAction);

	        // Add the required HTTP header to handle a multipart form data POST request
	        XHR.setRequestHeader('Content-Type','multipart/form-data; boundary=' + boundary);

	        // Make the request
	        XHR.send(data);
	}

	/**
	 * Send GTM tracking data
	 *
	 * @param {String} action The GTM action Datalayer Variable
	 * @param {String} question The GTM question Datalayer Variable
	 * @param {String} response The GTM response Datalayer Variable
	 *
	 * @returns void
	 */
	function sendGTMData(action, question, response) {
		dataLayer.push(
			{
				'event': 'communityGamingInteraction',
				'action': action,
				'question': question,
				'response': response
			}
		);
	}

	/**
	 * Convert a String into a human friendly format
	 *
	 * @param {String} idString The String to convert
	 *
	 * @returns A modified String of the provided ID
	 */
	function convertIDToHumanReadable(idString) {

		// Replace all underscores with a single space.
		// Convert the first character to uppercase.
		return idString.replace(/__|_/g, ' ').replace(/^./, idString.substring(0,1).toUpperCase());
	}
}

initialiseDecisionTree();