/**
 * @file    Drop down
 *          (applied e.g. in side nav trigger)
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    May 2018
 */

$.Sq = $.Sq || {};

$.Sq.Dropdown = (function() {
  const win = typeof window === "undefined" ? {} : window;

  /**
   * default config
   */
  const defaultConfig = {
    selector: ".c-dropdown",
    titleSelector: ".c-dropdown__title",
    bodySelector: ".c-dropdown__body",
    activeClass: "is-active",
    disabledClass: "js-dropdown-disabled",
    wrapClass: "js-dropdown",
    triggerClass: "js-dropdown-trigger",
    bodyClass: "js-dropdown-body",
    mobileOnlyClass: "js-dropdown-mobile-only",
    mobileOnly: false,
    breakPoint: 960,
    transition: 600, // this is max height transition defined in css
    maxHeightAttrName: "data-max-height",
    tabPanelSelector: '*[role="tabpanel"]'
  };

  /**
   * Add aria attributes
   *
   * @param trigger
   * @param body
   * @param index
   * @private
   */
  const _initAria = ({ trigger, body }, index) => {
    const triggerId = `nav-${index}__trigger`;
    const bodyId = `nav-${index}__body`;

    trigger.setAttribute("role", "button");
    trigger.setAttribute("aria-expanded", false);
    trigger.setAttribute("aria-controls", bodyId);
    trigger.setAttribute("tabindex", 0);

    body.setAttribute("role", "tabpanel");
    body.setAttribute("aria-hidden", true);
    body.setAttribute("aria-labelledby", triggerId);
  };

  /**
   * add classes for wrap, trigger and body
   *
   * @param item
   * @param conf
   * @private
   */
  const _addClasses = (item, conf) => {
    const { wrap, trigger, body } = item;
    const { wrapClass, triggerClass, bodyClass } = conf;

    wrap.classList.add(wrapClass);
    trigger.classList.add(triggerClass);
    body.classList.add(bodyClass);
  };

  /**
   * Check whether it's mobile view
   *
   * @return {boolean}
   * @private
   */
  const _isMobileView = conf => win.innerWidth < conf.breakPoint;

  /**
   * This function calculates the height of hidden accordion panels.
   * It highly depends on the structure of accordions
   *
   * @param elements
   * @return {number}
   */
  const calcHiddenHeight = elements =>
    Array.prototype.slice.call(elements).reduce((hiddenHeight, element) => {
      const isHidden = element.getAttribute("aria-hidden") === "true";
      const height =
        isHidden && element.children && element.children[0]
          ? element.children[0].offsetHeight
          : 0;

      return hiddenHeight + height;
    }, 0);

  /**
   * Calc max height by element's children
   * @param element
   * @param config
   * @return {number}
   */
  const calcMaxHeight = (element, config) =>
    Array.prototype.slice
      .call(element.children)
      .reduce(
        (totalHeight, child) =>
          totalHeight +
          child.offsetHeight +
          calcHiddenHeight(element.querySelectorAll(config.tabPanelSelector)),
        0
      );

  /**
   * Close dropdown
   *
   * @param item
   * @param item.wrap
   * @param item.trigger
   * @param item.body
   * @param config
   *
   * @private
   */
  const _close = ({ wrap, trigger, body }, config) => {
    wrap.classList.remove(config.activeClass);
    trigger.setAttribute("aria-expanded", false);
    body.setAttribute("aria-hidden", true);

    body.style["max-height"] = "0px";
  };

  /**
   * Reset dropdown
   *
   * @param wrap
   * @param trigger
   * @param body
   * @param config
   * @private
   */
  const _reset = ({ wrap, trigger, body }, config) => {
    wrap.classList.remove(config.activeClass);
    trigger.setAttribute("aria-expanded", false);
    body.setAttribute("aria-hidden", true);

    body.style["max-height"] = "";
  };

  /**
   * Open dropdown
   *
   * @param item
   * @param item.wrap
   * @param item.trigger
   * @param item.body
   * @param config
   *
   * @private
   */
  const _open = ({ wrap, trigger, body }, config) => {
    body.style["max-height"] = `${calcMaxHeight(body, config)}px`;

    wrap.classList.add(config.activeClass);
    trigger.setAttribute("aria-expanded", true);
    body.setAttribute("aria-hidden", false);
  };

  /**
   * Toggle dropdown (open/close)
   *
   * @param item
   * @param item.wrap
   * @param config
   *
   * @private
   */
  const _toggle = (item, config) => {
    const isExpend = item.wrap.classList.contains(config.activeClass);

    if (isExpend) {
      _close(item, config);
      return;
    }
    _open(item, config);
  };

  /**
   * Trigger's click event handler
   *
   * @param item
   * @param config
   * @return {function(*)}
   * @private
   */
  const _clickHandler = (item, config) => e => {
    e.preventDefault();

    if (item.wrap.classList.contains(config.disabledClass)) {
      return;
    }
    _toggle(item, config);
  };

  /**
   * Deactivate dropdowns
   *
   * @param item
   * @param config
   * @private
   */
  const _deactivate = (item, config) => {
    if (!item.wrap.classList.contains(config.disabledClass)) {
      item.wrap.classList.add(config.disabledClass);
    }
  };

  /**
   * activate dropdowns
   *
   * @param item
   * @param config
   * @private
   */
  const _activate = (item, config) => {
    if (item.wrap.classList.contains(config.disabledClass)) {
      item.wrap.classList.remove(config.disabledClass);
    }
  };

  /**
   * Window resize handler
   *
   */
  const winResizeHandler = (items, config, winWidth) =>
    $.Sq.debounce((trigger, pageWrap, navWrap, topItems, e) => {
      if (_isMobileView(config) || !config.mobileOnly) {
        if (winWidth === e.target.innerWidth) {
          items.forEach(item => {
            _reset(item, config);
            _activate(item, config);
          });
          return;
        }
      }

      items.forEach(item => {
        _reset(item, config);
        _deactivate(item, config);
      });
    }, 300);

  /**
   * Initialisation
   *
   * @param customConfig
   */
  const init = function(customConfig) {
    if (!win.document) {
      return;
    }

    // State
    this.config = Object.assign({}, defaultConfig, customConfig);
    this.items = [];
    this.winWidth = 0;

    // should active dropdown or not when page is loaded
    const shouldActivate =
      _isMobileView(this.config) || !this.config.mobileOnly;

    Array.prototype.slice
      .call(document.querySelectorAll(this.config.selector))
      .forEach((wrap, index) => {
        const trigger = wrap.querySelector(this.config.titleSelector);
        const body = wrap.querySelector(this.config.bodySelector);

        // do nothing when trigger/body is missing
        if (!trigger || !body) {
          return;
        }

        // Add class for mobile only
        if (this.config.mobileOnly) {
          wrap.classList.add(this.config.mobileOnlyClass);
        }

        // Update state
        const item = { wrap, trigger, body };
        this.items.push(item);

        // add js classes and aria attributes
        _addClasses(item, this.config);
        _initAria(item, index);

        // add click handler to dropdown triggers
        item.trigger.addEventListener(
          "click",
          _clickHandler(item, this.config)
        );

        // deactivate dropdowns if applied
        if (!shouldActivate) {
          _deactivate(item, this.config);
        }
      });

    // If items in state is empty, do not add resize handler to window
    if (this.items.length === 0) {
      return;
    }
    win.addEventListener(
      "resize",
      winResizeHandler(this.items, this.config, this.winWidth)
    );
  };

  return {
    init
  };
}());
