// Accordion

$.Sq = $.Sq || {};

$.Sq.Accordion = (function(module) {
  // Module variables

  const _instances = [];

  const CLASS_ACTIVE = 'is-active';
  const CLASS_CURRENT = 'is-current';
  const CLASS_OPEN_MULTIPLE = 'js-open-multiple';
  const CLASS_TABS = 'js-is-tabs';
  const CLASS_TABS_ENABLED = 'js-tabs-enabled';

  const DEFAULT_OPTIONS = {
    selector: '.c-accordion',
    itemSelector: '.c-accordion__item',
    panelSelector: '.c-accordion__panel',
    titleSelector: '.c-accordion__title',
    tabsBreakpoint: 900,
    tabsEnabled: false,
    openMultiple: false
  };

  const OPTIONS_MAP = [
    { className: CLASS_TABS_ENABLED, optionName: 'tabsEnabled' },
    { className: CLASS_OPEN_MULTIPLE, optionName: 'openMultiple' }
  ];

  function SqAccordion(elId, el, customOptions = {}) {
    //console.log(`Created SqAccordion '${elId}'`);

    // Variables

    var accordion = {};

    const _options = Object.assign({}, DEFAULT_OPTIONS, customOptions);
    const _$el = el;
    const _$items = Array.from(el.querySelectorAll(_options.itemSelector));
    const _$titles = Array.from(el.querySelectorAll(_options.titleSelector));
    const _$panels = Array.from(el.querySelectorAll(_options.panelSelector));

    //var _tabHeight = 0;
    var _isTabs = false;

    // Private methods

    /* function handleFocus(e) {
      toggleItem.call(e.currentTarget.parentNode);
    } */

    function toggleItem() {
      _$items.forEach(item => {
        item.classList.remove(CLASS_CURRENT);
        if (!_options.openMultiple && item !== this) {
          item.classList.remove(CLASS_ACTIVE);
        }
      });

      this.classList.add(CLASS_CURRENT);
      this.classList.toggle(CLASS_ACTIVE);
      accordion.handleResize();
    }

    function handleClick(e) {
      e.preventDefault();
      toggleItem.call(this.parentNode, e);
    }

    function initARIA() {
      _$el.setAttribute('role', 'tablist');
      _$el.setAttribute(
        'aria-multiselectable',
        !_isTabs && _options.openMultiple
      );

      _$titles.forEach(title => {
        let targetId = title.nextElementSibling.id;
        title.setAttribute('role', 'button');
        title.setAttribute('aria-expanded', false);
        title.setAttribute('aria-controls', targetId);
        title.setAttribute('tabindex', '0');
      });

      _$panels.forEach(panel => {
        let targetId = panel.previousElementSibling.id;
        panel.setAttribute('role', 'tabpanel');
        panel.setAttribute('aria-hidden', true);
        panel.setAttribute('aria-labelledby', targetId);
      });
    }

    function updateARIA() {
      _$el.setAttribute(
        'aria-multiselectable',
        !_isTabs && _options.openMultiple
      );

      _$items.forEach(item => {
        let isExpanded = item.classList.contains(CLASS_ACTIVE);
        let isCurrent = item.classList.contains(CLASS_CURRENT);
        let $title = item.querySelector(_options.titleSelector);
        let $panel = item.querySelector(_options.panelSelector);

        $title.setAttribute('aria-expanded', isExpanded);
        $title.setAttribute('aria-selected', isCurrent);
        $panel.setAttribute('aria-hidden', !isExpanded);
      });
    }

    function tab() {
      if (_$el.classList.contains('js-tabs-enabled')) {
        _$el.classList.add(CLASS_TABS);

        let $panelCurrent = _$el.querySelector(
          `.${CLASS_CURRENT} ${_options.panelSelector}`
        );
        let $panelInner = $panelCurrent.querySelector(
          `${_options.panelSelector}__inner`
        );
        let _tabHeight =
          _tabHeight || el.querySelector(_options.titleSelector).offsetHeight;

        _$el.style.height = $panelInner.offsetHeight + _tabHeight + 'px';
        $panelCurrent.style['min-height'] = $panelCurrent.style['max-height'] =
          $panelInner.offsetHeight + 'px';

        updateARIA();
      }
    }

    function unTab() {
      _$el.classList.remove(CLASS_TABS);

      _$el.style.height = 'auto';

      _$panels.forEach(panel => {
        panel.style['min-height'] = 0;
        panel.style['max-height'] =
          panel.querySelector(`${_options.panelSelector}__inner`).offsetHeight +
          'px';
      });

      updateARIA();
    }

    function init() {
      if (_$el.classList.contains('js-tabs-enabled')) {
        _$items[0].classList.add(CLASS_CURRENT);
      }

      _$titles.forEach(item => {
        item.addEventListener('click', handleClick);
      });

      initARIA();
    }

    // Public methods

    accordion.handleResize = function() {
      _isTabs =
        _options.tabsEnabled &&
        window.innerWidth > DEFAULT_OPTIONS.tabsBreakpoint;
      !_options.tabsEnabled || !_isTabs ? unTab() : tab();
    };

    init();

    return accordion;
  }

  // Module private methods

  function handleResize(e) {
    Object.keys(_instances).forEach(item => {
      _instances[item].handleResize();
    });
  }

  // Module public methods

  module.create = function(elId, el, customOptions) {
    if (_instances[elId]) {
      console.log(`Warning: SqAccordion instance '${elId}' already exists`);
      return;
    }

    if (!el) {
      console.log(`Warning: SqAccordion element '${el}' not found`);
      return;
    }

    // get additional options from element classes
    OPTIONS_MAP.forEach(option => {
      customOptions[option.optionName] = el.classList.contains(
        option.className
      );
    });

    _instances[elId] = SqAccordion(elId, el, customOptions);
    handleResize();
  };

  module.init = function(customOptions = {}) {
    let selector = customOptions.selector || DEFAULT_OPTIONS.selector;
    Array.from(document.querySelectorAll(selector)).forEach((item, index) => {
      module.create(`accordion_${index}`, item, customOptions);
    });
  };

  // Implementation

  window.addEventListener('resize', debounce(handleResize, 100));

  return module;
}($.Sq.Accordion || {}));
