/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
"use strict";
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
$("body")
  .removeClass("no-js")
  .addClass("js-enabled");

/*
--------------------
Modules initialisation
--------------------
*/

$(document).ready($ => {
  $.Sq = $.Sq || {};

  const init = () => {
    /*
         * Add svg icons
         */

    // breadcrumbs
    $.Sq.JsAddSvgs.init({
      selector: ".c-breadcrumbs__link",
      svgName: "angle-right",
      spanClass: "c-breadcrumbs__svg"
    });
    $.Sq.JsAddSvgs.init({
      selector: ".p-site-breadcrumbs",
      svgName: "angle-left",
      spanClass: "c-breadcrumbs__svg-mobile"
    });

    // "read more" related svg icons
    $.Sq.JsAddSvgs.init({
      selector: ".e-link--more-svg, .p-card__item, .c-side-links__link",
      svgName: "arrow-right",
      spanClass: "e-link--more-svg-icon"
    });

    // Social svg icons
    $.Sq.JsAddSvgs.init({
      selector: ".c-social-links__link",
      searchClass: true,
      classPrefix: "c-social-links__link--",
      spanClass: "c-social-links__svg"
    });

    $.Sq.JsAddSvgs.init({
      selector: ".c-tools__link",
      searchClass: true,
      classPrefix: "c-tools__link--",
      spanClass: "c-tools__svg"
    });

    // Search icon

    $.Sq.JsAddSvgs.init({
      selector: ".c-search__wrap",
      svgName: "search",
      spanClass: "c-search__icon"
    });

    $.Sq.JsAddSvgs.init({
      selector: ".c-search__btn",
      svgName: "search",
      spanClass: "c-search__svg"
    });

    /*
         * Init side nav drop down
         */

    new $.Sq.Dropdown.init({
      selector: ".c-side-nav",
      titleSelector: ".c-side-nav__heading",
      bodySelector: ".c-side-nav__body",
      activeClass: "is-active",
      mobileOnly: true
    });

    new $.Sq.Dropdown.init({
      selector: ".c-tools__item",
      titleSelector: ".c-tools__link",
      bodySelector: ".c-tools__body",
      activeClass: "is-active"
    });

    /*
         * card with icon init
         */
    $.Sq.JsAddSvgs.init({
      selector: ".p-card__icon",
      searchClass: true,
      classPrefix: "p-card__icon--",
      spanClass: "p-card__icon__svg"
    });
    /*
         * Init modules
         */
    $.Sq.Accordion.init();
    $.Sq.JsAddSvgs.init({
      selector: ".c-accordion__title",
      svgName: "angle-down",
      spanClass: "c-accordion__angle"
    });

    $.Sq.Table.init();
    $.Sq.SocialTab.init();

    $.Sq.Dropdown.init();
    $.Sq.Tools.init();

    $.Sq.Mainnav.init();
    $.Sq.JsAddSvgs.init({
      selector: ".has-sub .p-main-nav__link",
      svgName: "angle-right",
      spanClass: "p-main-nav__svg"
    });

    $.Sq.Sidenav.init();

    // Video icon
    $.Sq.VideoCover.init();
    $.Sq.JsAddSvgs.init({
      selector: ".c-video__play-btn",
      svgName: "play",
      spanClass: "c-video__play-btn-svg"
    });
  };

  $(init);
});

/*
--------------------
Additional Operations
--------------------
*/
