/**
 * @file    Description
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    May 2018
 */

$.Sq = $.Sq || {};

$.Sq.Mainnav = (function() {
  const win = typeof window === "undefined" ? {} : window;

  if (!win.document) {
    return;
  }

  /*
     * Constants
     */

  const PREFIX = "p-main-nav";

  // Init trigger constants
  const PAGE_WRAP_SELECTOR = "body";
  const NAV_SELECTOR = `.${PREFIX}`;
  const TRIGGER_SELECTOR = `.${PREFIX}__mobile-trigger`;

  const IS_NAV_ENABLED = "js-enabled-nav-trigger";
  const IS_OPEN_CLASS = "is-nav-open";

  // Init link clicking constants
  const NAV_WRAP_SELECTOR = `.${PREFIX}__list-wrap`;
  const TOP_ITEM_SELECTOR = `.${PREFIX}__item`;
  const SUB_LIST_SELECTOR = `.${PREFIX}__sub-list`;
  const TOP_LINK_CLASS = `${PREFIX}__link`;

  const LINK_BACK_CLASS = `${PREFIX}__link-back`;
  const TOP_LINK_TEXT_CLASS = `${PREFIX}__text`;

  const IS_SHOWING_LEVEL_TWO = "is-showing-level-2";
  const IS_CURRENT_CLASS = "is-current";
  const HAS_SUB_CLASS = "has-sub";

  const SPAN_HREF_ATTR_NAME = "data-href";

  const BREAK_POINT = 960;

  let winWidth = 0;

  /*
     * Shared func
     */

  /**
   * Check whether it's mobile view
   *
   * @return {boolean}
   * @private
   */
  const _isMobileView = () => win.innerWidth < BREAK_POINT;

  /**
   * Clone a navigation item's link, and insert it back as a Back Link,
   * which will be used to navigate from Level Two back to Level One
   *
   * @param topLink
   * @private
   */
  const _insertLinkBack = topLink => {
    const linkBack = topLink.cloneNode(true);

    linkBack.classList.add(LINK_BACK_CLASS);

    topLink.parentNode.insertBefore(linkBack, topLink);
  };

  /**
   * Get a node's "href" attribute
   *
   * @param node
   * @return {string}
   * @private
   */
  const _getLink = node => node ? node.getAttribute("href") : "#";

  /**
   * Level one links' inner text click handler
   * @param e
   * @private
   */
  const _textInteractHandler = e => {
    const link = e.target.getAttribute(SPAN_HREF_ATTR_NAME);
    if (!link || (e.button !== 0 && e.keyCode !== 13)) {
      return;
    }

    e.stopPropagation();
    win.location = link;
  };

  /**
   * wrap node's text content
   * @param linkNode
   * @return {HTMLSpanElement}
   */
  const _wrapInnerText = linkNode => {
    // get the href value
    const dataHref = _getLink(linkNode);

    // create the wrapped text content, set attributes and add event handler
    const textNode = win.document.createElement("span");

    textNode.className = TOP_LINK_TEXT_CLASS;
    textNode.textContent = linkNode.textContent;
    textNode.setAttribute("data-href", dataHref);
    textNode.setAttribute("tabindex", "0");

    textNode.addEventListener("click", _textInteractHandler);
    textNode.addEventListener("keyup", _textInteractHandler);

    return textNode;
  };

  /**
   * Replace node's textContent with a wrapped textContent
   * @param node
   */
  const _replaceTextNode = node => {
    const textSpan = _wrapInnerText(node);

    node.textContent = "";
    node.appendChild(textSpan);
  };

  /**
   * Whether child is a descendant of a parent
   * @param child
   * @param parent
   * @return {boolean}
   */
  const _isDescendant = (child = {}, parent) => {
    let result = false;
    let stop = false;

    while (child.parentNode && !stop) {
      if (child.parentNode === parent) {
        result = true;
        stop = true;
      }

      child = child.parentNode;
    }

    return result;
  };

  /*
     * Handlers
     */

  /**
   * trigger click handler
   *
   * @param trigger
   * @param pageWrap
   * @param e
   * @private
   */
  const _triggerClickHandler = (trigger, pageWrap, e) => {
    e.preventDefault();

    if (pageWrap.classList.contains(IS_OPEN_CLASS)) {
      pageWrap.classList.remove(IS_OPEN_CLASS);
      trigger.setAttribute("aria-expanded", false);
      return;
    }

    pageWrap.classList.add(IS_OPEN_CLASS);
    trigger.setAttribute("aria-expanded", true);
  };

  /**
   * Close the nav when interacting with element outside the entire navigation
   * @param trigger
   * @param pageWrap
   * @param entireNav
   * @param e
   */
  const outsideNavIneractHandler = (trigger, pageWrap, entireNav, e) => {
    // IF is not on mobile view OR the nav is closed OR clicked element is inside the nav
    if (
      !_isMobileView() ||
      !pageWrap.classList.contains(IS_OPEN_CLASS) ||
      _isDescendant(e.target, entireNav)
    ) {
      return;
    }

    // IF e is keypress, and it is not "Tab"
    if (e.keyCode && e.keyCode !== 9) {
      return;
    }

    trigger.click();
  };

  /**
   * Level one links' back links click handler
   *
   * @param navWrap
   * @param topItem
   * @return {function(*)}
   * @private
   */
  const _linkBackClickHandler = (navWrap, topItem) => e => {
    if (!_isMobileView()) {
      return;
    }

    e.preventDefault();

    navWrap.classList.remove(IS_SHOWING_LEVEL_TWO);
    topItem.classList.remove(IS_CURRENT_CLASS);
  };

  /**
   * Level one links click handler
   *
   * @param navWrap
   * @param topItem
   * @return {function(*)}
   * @private
   */
  const _topLinkClickHandler = (navWrap, topItem) => e => {
    if (!_isMobileView()) {
      return;
    }

    e.preventDefault();

    navWrap.classList.add(IS_SHOWING_LEVEL_TWO);
    topItem.classList.add(IS_CURRENT_CLASS);
  };

  /**
   * window resize handler
   *
   * @param trigger
   * @param pageWrap
   * @param navWrap
   * @param topItems
   */
  const windowResizeHandler = $.Sq.debounce(
    (trigger, pageWrap, navWrap, topItems, e) => {
      const currentWidth = e.target.innerWidth;
      if (currentWidth === winWidth) {
        return;
      }

      trigger.setAttribute("aria-expanded", false);
      pageWrap.classList.remove(IS_OPEN_CLASS);
      navWrap.classList.remove(IS_SHOWING_LEVEL_TWO);
      topItems.forEach(el => {
        el.classList.remove(IS_CURRENT_CLASS);
      });

      winWidth = currentWidth;
    }
  );

  /*
     * Initializer
     */

  /**
   * Initialise trigger
   *
   * @param trigger
   * @param pageWrap
   */
  const initTrigger = (trigger, pageWrap) => {
    if (!trigger) {
      return;
    }

    if (!pageWrap.classList.contains(IS_NAV_ENABLED)) {
      pageWrap.classList.add(IS_NAV_ENABLED);
    }

    trigger.addEventListener(
      "click",
      _triggerClickHandler.bind(null, trigger, pageWrap)
    );
  };

  /**
   * Initialise nav items
   *
   * @param trigger
   * @param pageWrap
   * @param navWrap
   * @param entireNav
   * @param topItems
   */
  const initItems = (trigger, pageWrap, entireNav, navWrap, topItems) => {
    if (topItems.length === 0) {
      return;
    }

    winWidth = win.innerWidth;

    topItems.forEach(topItem => {
      const topLink = topItem.querySelector(`.${TOP_LINK_CLASS}`);

      if (!topItem.querySelector(SUB_LIST_SELECTOR)) {
        return;
      }

      topItem.classList.add(HAS_SUB_CLASS);
      _insertLinkBack(topLink);
      _replaceTextNode(topLink);

      topItem
        .querySelector(`.${LINK_BACK_CLASS}`)
        .addEventListener("click", _linkBackClickHandler(navWrap, topItem));

      topLink.addEventListener("click", _topLinkClickHandler(navWrap, topItem));
    });

    win.addEventListener(
      "resize",
      windowResizeHandler.bind(null, trigger, pageWrap, navWrap, topItems)
    );
    win.document.addEventListener(
      "click",
      outsideNavIneractHandler.bind(null, trigger, pageWrap, entireNav)
    );
    win.document.addEventListener(
      "keyup",
      outsideNavIneractHandler.bind(null, trigger, pageWrap, navWrap)
    );
  };

  const init = () => {
    const entireNav = win.document.querySelector(NAV_SELECTOR);
    const trigger = win.document.querySelector(TRIGGER_SELECTOR);
    const pageWrap = win.document.querySelector(PAGE_WRAP_SELECTOR);
    const navWrap = win.document.querySelector(NAV_WRAP_SELECTOR);
    const topItems = Array.prototype.slice.call(
      navWrap.querySelectorAll(TOP_ITEM_SELECTOR)
    );

    initTrigger(trigger, pageWrap);
    initItems(trigger, pageWrap, entireNav, navWrap, topItems);
  };

  return {
    init
  };
}());
