/**
 * @file    Description
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    May 2018
 */

(function() {
    const win = typeof window === 'undefined' ? {} : window;

    if (!win.document) {
        return;
    }

    const SELECTOR = `.c-breadcrumbs`;
    const IS_TOP_LEVEL_CLASS = 'is-top-level';


    /**
     * Add class for breadcrumbs that have only one child
     *
     * @param el
     * @private
     */
    const _addCountClass = (el) => {
        const length = el.children.length;

        if (length > 1) {
            return;
        }

        el.classList.add(IS_TOP_LEVEL_CLASS);
    };

    const init = () => {
        // append svg shown on mobile view
        Array.prototype.slice.call(win.document.querySelectorAll(SELECTOR)).forEach((el) => {
            _addCountClass(el);
        });
    };

    init();
})();
