/* add smooth scrool to all <a href="#link"/> anchors. The codes could be moved to global.js in the footer to enable this feature side-wide.
new SmoothScroll('a[href^="#"]', {
  topOnEmptyHash: false,
  updateURL: false
});*/

if ($('.o-long-doc').length > 0) {
  /* Generate table of content html*/
  var tag, previousTag, title, idStr;
  var htmlStr = '';

  $('#main-content h2, #main-content h3, #main-content h4').each(function(
    index
  ) {
    tag = $(this).prop('tagName');
    title = $(this).text();
    //idStr = title.toLowerCase().replace(/\s+/g, "-").replace("$", "dollar").replace("/", "-or-");
    idStr = index;

    $(this).attr('id', idStr);

    if (tag === 'H2') {
      if (previousTag === 'H2') {
        htmlStr = htmlStr + '</li>';
      } else if (previousTag === 'H3') {
        htmlStr = htmlStr + '</li></ul>';
      } else if (previousTag === 'H4') {
        htmlStr = htmlStr + '</li></ul></li></ul>';
      }
      htmlStr =
        htmlStr +
        '<li class="toc-item toc-item__h2"><a href="#' +
        idStr +
        '">' +
        title +
        '</a>';
    }

    if (tag === 'H3') {
      if (previousTag === 'H3') {
        htmlStr = htmlStr + '</li>';
      } else if (previousTag === 'H2') {
        htmlStr = htmlStr + '<ul class="toc-list toc-list__h3" >';
      } else if (previousTag === 'H4') {
        htmlStr = htmlStr + '</li></ul>';
      }
      htmlStr =
        htmlStr +
        '<li class="toc-item toc-item__h3"><a href="#' +
        idStr +
        '">' +
        title +
        '</a>';
    }

    if (tag === 'H4') {
      if (previousTag === 'H4') {
        htmlStr = htmlStr + '</li>';
      } else if (previousTag === 'H3') {
        htmlStr = htmlStr + '<ul class="toc-list toc-list__h4" >';
      }
      htmlStr =
        htmlStr +
        '<li class="toc-item toc-item__h4"><a href="#' +
        idStr +
        '">' +
        title +
        '</a>';
    }

    previousTag = tag;
  });

  $('#toc-list').append(htmlStr);

  /* setup style and scroll in toc for desktop */
  if ($(window).width() > 960) {
    // set show and hide toc button
    var tocJQ = $('.toc');
    var btnShowHideJQ = $('#btn-show-hde-toc');
    var btnImgShowHideJQ = $('#btn-show-hde-toc img');
    btnImgShowHideJQ.css({
      bottom: $(window).height() / 2,
      right:
        $(window).width() / 2 - $('.o-content').width() / 2 + tocJQ.width() / 2
    });
    btnShowHideJQ.click(function() {
      tocJQ.toggle();
      if (tocJQ.css('display') === 'none') {
        btnImgShowHideJQ.attr(
          'src',
          'styles/mysource_files/long-doc-arrow-left.svg'
        );
        btnImgShowHideJQ.css('right', '10px');
      } else {
        btnImgShowHideJQ.attr(
          'src',
          'styles/mysource_files/long-doc-arrow-right.svg'
        );
        btnImgShowHideJQ.css(
          'right',
          $(window).width() / 2 -
            $('.o-content').width() / 2 +
            tocJQ.width() / 2
        );
      }
    });

    $(document).scroll(function() {
      /* dynamic styles */
      // fix the position of the toc when scrolling to main content
      if ($(document).scrollTop() >= $('#long-doc-main-title').offset().top) {
        $('#toc-main-heading').css('position', 'fixed');
        $('#toc-list').css('position', 'fixed');
      } else {
        $('#toc-main-heading').css('position', 'static');
        $('#toc-list').css('position', 'static');
      }

      // fix the position of the toc when the footer is visible
      if (
        $(document).scrollTop() + $(window).height() >=
        $('footer').offset().top
      ) {
        $('#toc-list').css('height', $(window).height() - 200);
      } else {
        $('#toc-list').css('height', $(window).height() - 100);
      }

      /* Scroll sidebar when scrollong main contents */
      // get the curremt top visible element in the main content
      var topContentViewportElsArray = $.grep(
        $('.o-content h2, .o-content h3, .o-content h4'),
        function(item) {
          return (
            $(item).offset().top + $(item).height() >= $(document).scrollTop()
          );
        }
      );
      var topContentViewportEl = topContentViewportElsArray[0];
      var currentTocLinkJQ = $(
        '.toc a[href="#' + $(topContentViewportEl).attr('id') + '"]'
      );

      // set the link to the el above to be active
      $('.toc a').removeClass('current');
      currentTocLinkJQ.addClass('current');

      // detect if the current top el in o-content is also visible in sidebar
      var tocListJQ = $('.toc-list');

      var currentLinkLiTop = currentTocLinkJQ.parent().offset().top;
      var tocListTop = tocListJQ.offset().top;
      var currentLinkLiBottom =
        currentLinkLiTop + currentTocLinkJQ.parent().height();
      var tocListBottom = tocListTop + tocListJQ.height();

      // scroll to the link in the sidebar if it is not visible
      if (currentLinkLiTop < tocListTop) {
        tocListJQ.stop().animate(
          {
            scrollTop: tocListJQ.scrollTop() - (tocListTop - currentLinkLiTop)
          },
          1000,
          'swing'
        );
      }
      if (currentLinkLiBottom > tocListBottom) {
        tocListJQ.stop().animate(
          {
            scrollTop:
              tocListJQ.scrollTop() +
              tocListJQ.height() +
              (currentLinkLiBottom - tocListBottom)
          },
          1000,
          'swing'
        );
      }
    });
  }
}
