/**
 * @file    Add video cover (play button and cpation) to responsive videos
 *
 * @author  Chi Yan <cyan@squiz.net>
 * @date    May 2018
 */

$.Sq = $.Sq || {};

$.Sq.VideoCover = (function() {
  const win = typeof window === "undefined" ? {} : window;

  const defaultConfig = {
    selector: ".c-video",
    iframeSelector: "iframe",
    buttonClass: "c-video__play-btn",
    buttonText: "Play video",
    buttonTextClass: "c-video__text",
    coverClass: "c-video__cover",
    captionClass: "c-video__caption",
    playedClass: "js-video-played",
    attrCoverImage: "data-cover-image",
    attrCaptionText: "data-caption-text",
    attrCaptionHTML: "data-caption-html"
  };

  /**
   * Advanced Create Element function
   *
   * @param tag
   * @param className
   * @param innerText
   * @param innerHTML
   * @param listeners
   * @param inlineStyle
   * @return {Object|*|ActiveX.IXMLDOMElement|HTMLAnchorElement|HTMLAppletElement|HTMLAreaElement}
   */
  const createElement = (
    tag,
    { className, innerText, innerHTML, listeners, inlineStyle }
  ) => {
    const el = win.document.createElement(tag);
    el.classList.add(className);

    if (inlineStyle) {
      el.setAttribute("style", inlineStyle);
    }

    if (innerHTML) {
      el.innerHTML = innerHTML;
    } else if (innerText) {
      el.innerText = innerText;
    }

    if (listeners) {
      Object.keys(listeners).forEach(event => {
        el.addEventListener(event, listeners[event]);
      });
    }

    return el;
  };

  /**
   * e.g. should prepend "?" or "&" before "autoplay=true" appended to URL
   *
   * @param url
   * @param query
   * @return {string}
   */
  const appendURLQuery = (url = "", query) => {
    const divider = url.indexOf("?") > 0 ? "&" : "?";

    return `${url}${divider}${query}`;
  };

  /**
   * Button click handler, clicking should play the embed video
   *
   * @param iframe
   * @param cover
   * @param config
   * @param e
   */
  const btnClickHandler = (iframe, cover, config, e) => {
    e.preventDefault();

    const newSrc = appendURLQuery(iframe.getAttribute("src"), "autoplay=true");
    iframe.setAttribute("src", newSrc);

    cover.classList.add(config.playedClass);
  };

  /**
   * recursively call appendChild to build a dom tree
   *
   * @param root
   * @param self
   * @param children
   */
  const appendByDomTree = ({ self, children }) => {
    if (!self || !children) {
      return;
    }

    children.forEach(child => {
      child.self && self.appendChild(child.self);
      appendByDomTree(child);
    });
  };

  const init = customConfig => {
    if (!win.document) {
      return;
    }

    const config = Object.assign({}, defaultConfig, customConfig);

    Array.prototype.slice
      .call(win.document.querySelectorAll(config.selector))
      .forEach(wrap => {
        const iframe = wrap.querySelector(config.iframeSelector);
        const coverImage = iframe.getAttribute("data-cover-image");

        if (!iframe || !coverImage) {
          return;
        }

        // create cover, btn, btnText
        const cover = createElement("div", {
          className: config.coverClass
        });
        const btn = createElement("button", {
          className: config.buttonClass,
          inlineStyle: `background-image: url("${coverImage}")`,
          listeners: {
            click: btnClickHandler.bind(null, iframe, cover, config)
          }
        });
        const btnText = createElement("span", {
          className: config.buttonTextClass,
          innerText: config.buttonText
        });

        // create caption
        const capText = iframe.getAttribute(config.attrCaptionText);
        const capHTML = iframe.getAttribute(config.attrCaptionHTML);

        const hasCaption = capText || capHTML;
        const caption = hasCaption
          ? createElement("div", { className: config.captionClass })
          : null;
        const captionText =
          capText || capHTML
            ? createElement("p", { innerText: capText, innerHTML: capHTML })
            : null;

        // tree to build
        const fragment = win.document.createDocumentFragment();
        const tree = {
          self: fragment,
          children: [
            {
              self: cover,
              children: [
                {
                  self: btn,
                  children: [
                    {
                      self: btnText
                    }
                  ]
                },
                {
                  self: caption,
                  children: [
                    {
                      self: captionText
                    }
                  ]
                }
              ]
            }
          ]
        };

        appendByDomTree(tree);
        wrap.appendChild(fragment);
      });
  };

  return {
    init
  };
}());
